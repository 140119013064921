import { link } from "framer-motion/client";

export const updates = [
  {
    version: "Version 2025.1.2",
    changes: [
      "New multiplayer maps: Big City, Empty Plane.",
      "New fireworks with skin: Great Eagle, Nebula Core, Dahlia Rocket, Rainbow Nova, Cake 2025.",
      "Multiplayer: support some items in special category.",
      "Easier controls: delete, move, connect fuse lines.",
      "Firecracker: improve explode smoke (8 firecrackers).",
    ],
  },
  {
    version: "Version 2025.1.1",
    changes: [
      "New mode: Portrait (Close-up shots).",
      "New fountain: Beehive, Silver Water.",
      "New fireworks: 50 Random Shots.",
      "Revamp: Fountain 01, 02.",
      "Apply physic to Roman Candle.",
    ],
  },
  {
    version: "Version 2024.12.1",
    changes: [
      "Daily Rewards: Thor Thunder, Smoking Pumpkin, Teddy Bear, Military Mortar, Umbrella, UFO Spinner, Minigun Candle, Battery, Monster Firecracker, Gold Shining, Hwacha, Umbrella Fountain, Swimming Stars, Mini Sky Shot, Mini Cake, God of Salute, Crackling Sliver, Kwitis Barrage, Rain of Fire, Super Toy Car, Air Defense, DMX Fire Machine, Military Rocket, Twister Drone, Sparkle Wheel, Girandola, Anti Venom, Multi Flame Machine, Smoky Twister, Airframe Missile.",
      "New Shells & Fireworks: Sky High, Brocade 10 11, Galactic Ring 2.",
      "Revamp comets, lighter tool, connection line tool.",
      "Add show frame rate option.",
    ],
  },
  {
    version: "Version 2024.10.1",
    changes: [
      " Happy Halloween Together.",
      " New map: Graveyard.",
      " New fireworks: Jellyfish, Strobe 11, Horsetail 13, 14, Ghost Shell 08.",
      " New cakes: Phantom Flames, Azure Skyburst.",
      " Optimized Farm map.",
      " Applied physic for rockets, and cakes.",
      " Bug fixes and enhancements.",
    ],
  },
  {
    version: "Version 2024.9.2",
    changes: [
      "New seasonal map: Lantern Festival.",
      "New fireworks: Flash Bang, Salute 06 07, Classic 43.",
      "New cake:  Artistic Pyro, Lantern Skyfall, Moonlit Radiance.",
    ],
  },
  {
    version: "Version 2024.9.1",
    changes: [
      "New map: Stadium + mini football game.",
      "New props: Air Smoke Bomb.",
      "Revamp fireworks sound effects.",
    ],
  },
  {
    version: "Version 2024.8.1",
    changes: [
      "New fireworks: Airstrike Salute, Airstrike Rocket, Strobe 09 10, Classic 41 42.",
      "New props: Ground Strobe.",
      "Revamp grenade explosion effects, UI.",
      "Multiplayer: Apply damage to player.",
    ],
  },
  {
    version: "Version 2024.7.1",
    changes: [
      "New Steampunk map.",
      "Flashlight, Fuse Line.",
      "Character skin.",
      "Singleplayer: Won’t fall down if wear Jump Shoes.",
      "Singleplayer: Bug fixes and enhancements.",
    ],
  },
  {
    version: "Version 2024.6.1",
    changes: [
      "Firework effects more realistic (horsetail, kwitis, palm, rings, strobe).",
      "Physical rocket launcher (the red one).",
      "Multiplayer: Firecracker, Props categories.",
      "Multiplayer bug fixes: https://youtu.be/ZNm0FFairB0",
    ],
  },
  {
    version: "Version 2024.5.2",
    changes: [
      "Firework effects more realistic.",
      "New fireworks: Cake Saturn Missile.",
    ],
  },
  {
    version: "Version 2024.5.1",
    changes: [
      "Multiplayer: Explosion category.",
      "Multiplayer: Host limits specific items.",
      "Bug fixes and enhancements.",
    ],
  },
  {
    version: "Version 2024.4.3",
    changes: [
      "Multiplayer: Kick, Cool Down features, and Customizable cake category.",
      "New fireworks: Dahlia 20, Crossette 19, Ghost Shell 07.",
    ],
  },
  {
    version: "Version 2024.4.2",
    changes: ["Multiplayer: Chat feature and Customizable shell category."],
  },
  {
    version: "Version 2024.4.1",
    changes: [
      "Early access Multiplayer Event",
      "New shells: Brocade 08; Crossette 16, 17, 18; Salute 05.",
      "Firework effects more realistic.",
    ],
  },
  {
    version: "Version 2024.1.1",
    changes: [
      "New props: Confetti Cannon, Confetti Shooter, Confetti Shooter 2, Dual Spinner, Spray Machine.",
      "New shells: Fish in water.",
      "New 51 customizable cakes.",
      "Firework effects more realistic.",
    ],
  },
  {
    version: "Version 2023.12.1",
    changes: [
      "Merry Christmas!",
      "New firecrackers: Red Firecracker Pack, Tiger Roar, Blaze Tank, Thunder Tank, Fury Tank, Plane.",
      "New explosions: Naval Mine, Ground Mine.",
      "New specials: Hot-air Balloon.",
    ],
  },
  {
    version: "Version 2023.10.1",
    changes: [
      "Happy Halloween!",
      "Moving the train.",
      "Support mouse and keyboard.",
    ],
  },
  {
    version: "Version 2023.9.1",
    changes: [
      "New specials: RPG Firework gun.",
      "Dummy cars in the Big City map.",
      "All fireworks are now available in Build mode.",
    ],
  },
  {
    version: "Version 2023.8.2",
    changes: [
      "6 new shells: Crossette 11, 12, 13; Dahlia 14, 15, 16.",
      "3 new cakes: Eye of Osiris, Garden of Eden, Azure Skies.",
    ],
  },
  {
    version: "Version 2023.8.1",
    changes: [
      "14 new shells: Horsetail 06, 07, 08; Dahlia 11, 12, 13; Chrysanthemum 16, 17, 18; Strobe 08; Ring Strobe 02; Palm 04; Crossette 10; Butterfly 01.",
    ],
  },
  {
    version: "Version 2023.7.2",
    changes: ["New map: Farm.", "New explosion: Molotov Cocktail."],
  },
  {
    version: "Version 2023.7.1",
    changes: ["July 4th shows."],
  },
  {
    version: "Version 2023.6.1",
    changes: [
      "New map: Big City.",
      "Added events feature.",
      "New fireworks: July, July Rainy, Independence Day, Dragon Crossette, Devils Raise, Salute Huge Night, Liberty, Celebration.",
      "New customizable shells: Classic 37; Brocade 05, 06; Crossette 06, 07, 08; Chrysanthemum 14, 15, Salute 03.",
      "New customizable cakes: 16 Shots (No Tail).",
      "Stuff for July 4th.",
    ],
  },
  {
    version: "Version 2023.5.2",
    changes: [
      "New special: Magic Reloadable Tube.",
      "New fireworks skin: Carnival (Mine 07), Uranus (Big Shell 02), Glare Zone (Fountain 01).",
      "New props: Traffic Cone, Kwitis Stand.",
      "New shell and cake: Classic 32, 33, 34, 35, Cake 30.",
      "Updated on Store categories view.",
      "Updated on helicopter and plane: auto rotation (easier to control).",
    ],
  },
  {
    version: "Version 2023.5.1",
    changes: [
      "New cakes: Rebel Waves, Red Glittering, Gold Titanium, Strobing Missiles, Red Alert, Pyro Fan, Salute, 600 Shots Salute, Straight Shootout.",
      "New candle: Crunchy Balls, Parachute Flare.",
      "New shell: Salute 02.",
      "Others: Green Smoke, Flare Gun, Thin Sparkler, Gold Sparkler Candle, Sky Lantern, Smoke Flare.",
    ],
  },
  {
    version: "Version 2023.4.3",
    changes: ["New explosion: Spaceship."],
  },
  {
    version: "Version 2023.4.2",
    changes: [
      "New map: Samurai.",
      "New fireworks: Extreme Shock.",
      "New shells: Horsetail 4, Classic 30, 31, Strobe 07.",
      "Improved lights in Town Map.",
      "Bug fixes: save/load Reloadable Tube.",
    ],
  },
  {
    version: "Version 2023.4.1",
    changes: [
      "New Props: Speaker.",
      "New Firecracker String.",
      "New Reloadable Tubes: Size L, XL.",
      "New Shells: Classic 28, 29; Dahlia 09.",
      "New Fireworks: Aurora.",
    ],
  },
  {
    version: "Version 2023.3.2",
    changes: [
      "New shells: Classic 22 to 27.",
      "10 new fireworks skins.",
      "Long firecracker string.",
    ],
  },
  {
    version: "Version 2023.3.1",
    changes: [
      "New rockets: 3 Stage Nuclear, Kwiton Bomb, Salute.",
      "New shells: Salute, Kwiton Bomb.",
      "Some new rocket skins.",
    ],
  },
  {
    version: "Version 2023.2.3",
    changes: [
      "Nuclear Rocket.",
      "New fireworks: Classic 19, 20, 21.",
      "New Rocket category.",
    ],
  },
  {
    version: "Version 2023.2.2",
    changes: [
      "Fixed Firework Spawning Tool bug.",
      "New Firework Spawning Tool.",
      "New fireworks: Classic 17, 18.",
      "Some new sound effects.",
    ],
  },
  {
    version: "Version 2023.2.1",
    changes: [
      "Reloadable Tubes: Size S, M.",
      "New firecrackers: Dum Dum 30, 50, Scream 2, Mega 1.",
      "New cakes: Blossom, Ethereal, Bouquet, Howling, Dots.",
      "New fireworks: Chrysanthemum 12, 13, Galactic Ring, Ring Canister.",
    ],
  },
  {
    version: "Version 2023.1.2",
    changes: [
      "New fireworks shells: Chrysanthemum 09, 10, 11, Strobe 06, Ghost Shell 06, Classic 13, 14, 15, 16.",
      "New firecrackers: Kwitis, Kwitis Boom Shell.",
      "New launcher: Skyrocket.",
    ],
  },
  {
    version: "Version 2023.1.1",
    changes: [
      "Remote firing system.",
      "Configurable for Big shell, Mine/Fountain.",
      "Binoculars.",
      "Flamethrower.",
      "Small helicopter.",
      "Real helicopter with controls.",
      "New cakes: Cake 18 (Phoenix), Cake 19 (Combination), Cake 20 (42 Straight Shots), Cake 21 (Spiral Shots), Cake 22 (Circular Bursts).",
    ],
  },
  {
    version: "Version 2022.12.3",
    changes: [
      "Props: Music Box, Christmas Tree.",
      "Firecracker: Candy.",
      "New cakes: 60 Fan Shots, 100 Fan Shots, 200 Fan Shots.",
      "Special: Airplanes.",
    ],
  },
  {
    version: "Version 2022.12.2",
    changes: [
      "Merry Christmas!",
      "Winter and snow effects.",
      "Snow man 1, 2.",
      "Magic ice.",
      "New cakes: 25 Trailing Bubbles, 35 Rockers, Ring Makers.",
    ],
  },
  {
    version: "Version 2022.12.1",
    changes: ["Shotguns, Rifle, Gun Target Stand."],
  },
  {
    version: "Version 2022.11.3",
    changes: ["New 'Revolver' and Skeet Shooting Machine."],
  },
  {
    version: "Version 2022.11.2",
    changes: [
      "Updated on cake that allows you to select shell effect, high, color.",
    ],
  },
  {
    version: "Version 2022.11.1",
    changes: ["Bingo 2.", "New 'Ball' launcher.", "Day night system."],
  },
  {
    version: "Version 2022.10.3",
    changes: ["Performance optimization and bug fixes."],
  },
  {
    version: "Version 2022.10.2",
    changes: [
      "Happy Halloween!",
      "New firecrackers: Skulls, Pumpkin, Witch Hat.",
      "New props: Torch, Candle.",
      "New map: Horror Mansion.",
      "Optimized lighting performance.",
      "Bingo is now in the shop.",
    ],
  },
  {
    version: "Version 2022.10.1",
    changes: [
      "New maps: Empty Town, Town Lite.",
      "New firecrackers: Spinning Det Cord, Mine 07.",
      "Stuff: open and close doors.",
      "Stuff: pick up and throw some objects.",
      "Stuff: strong hand to throw stuff farther.",
      "Settings: more options on Performance settings.",
    ],
  },
  {
    version: "Version 2022.9.4",
    changes: [
      "Improved experience on Lighter, Fuse Line tools.",
      "New Super Slow fuse.",
      "New Town map.",
    ],
  },
  {
    version: "Version 2022.9.3",
    changes: [
      "New explosions: NukeCraft, Nuke, Tiny Nuke.",
      "Jump Shoe.",
      "Improved on bombs: shake the earth, shockwave effect, embers effect, affect explosion to nearby things.",
    ],
  },
  {
    version: "Version 2022.9.2",
    changes: ["New explosions: Grenade M67, M84, German Stick, Smoke Bombs."],
  },
  {
    version: "Version 2022.9.1",
    changes: ["New explosion items: Black Powder Box, TNTs, Oil Drum."],
  },
  {
    version: "Version 2022.8.3",
    changes: [
      "Magnet connection line tool.",
      "New firecrackers: Spinning Cobra, Spinning Wheel, Spinning Firecracker, Butterfly.",
    ],
  },
  {
    version: "Version 2022.8.2",
    changes: ["Roman Candle.", "Hold and throw stuff."],
  },
  {
    version: "Version 2022.8.1",
    changes: [
      "New fireworks: Classic 01-12, Dahlia 06-08, Chrysanthemum 07-08, Big Shell 05.",
      "New firework options: Color, Distance, Fuse time, Size, Launcher type: tube, rocket, missile.",
      "Options to change move speed and control sensitivity.",
      "Options to change screen orientations.",
    ],
  },
  {
    version: "Version 2022.7.4",
    changes: [
      "New Firecrackers: FCR 04, FCR 05, Scream 1.",
      "New Fireworks: Ring Strobe, Ring Horsetail.",
      "Improved fireworks: brighter and hue effects.",
    ],
  },
  {
    version: "Version 2022.7.3",
    changes: ["New Map."],
  },
  {
    version: "Version 2022.7.1",
    changes: [
      "Added option to select the launcher type for each firework shell including tube, rocket, missile.",
      "New firecrackers: X1, X3, X6, X8, and Strobe Rocket 2, 3.",
      "Enhanced tail effects.",
      "Reduced number of cars so you have more space to play.",
      "Added option to select skylight direction.",
      "Bug fixes.",
    ],
  },
  {
    version: "Version 3.4.0",
    changes: [
      "Added real Racks. Control timer and fireworks in each mortar tube.",
      "Added move, delete launcher features.",
      "Other enhancements and bug fixes.",
    ],
  },
  {
    version: "Version 3.3.0",
    changes: [
      "New fireworks: BINGO!, Big Shell 3, 4, Strobe Rocket.",
      "Different fireworks sizes, different tube/rocket skin sizes.",
      "Other enhancements and bug fixes.",
    ],
  },
  {
    version: "Version 3.2.0",
    changes: ["Big fireworks.", "Connection line."],
  },
  {
    version: "Version 3.1.0",
    changes: [
      "Optimize: compress sound to mp3 file if possible.",
      "Cherry firework.",
      "Fix firecracker string light up issue.",
    ],
  },
  {
    version: "Version 3.0.0",
    changes: [
      "New fireworks FPS mode.",
      "Allow you to walk around the town, do all the fun stuff with fireworks. Explode your neighborhood house and cars.",
    ],
  },
  {
    version: "Version 2.2.0",
    changes: [
      "New firework type: Cake.",
      "New scene: Town.",
      "25 new fireworks added.",
      "Import-export feature.",
      "Favorite fireworks list feature.",
      "Multi selection by list view.",
      "Quick look option for type Comet.",
    ],
  },
  {
    version: "Version 2.1.0",
    changes: [
      "Option to input the values by keyboard.",
      "Option to random time/height/angle by specific ranges.",
      "Other enhancements on lighting, setup, and bug fixes.",
    ],
  },
  {
    version: "Version 2.0.0",
    changes: ["New Idle Mode. Tap on fireworks to fire.", "Bug fixes."],
  },
  {
    version: "Version 1.3.0",
    changes: [
      "Option to set up fly speed.",
      "Option to set up 4”, 6”, 8”, 10”, 12” sizes.",
      "Other enhancements on setup.",
    ],
  },
  {
    version: "Version 1.2.0",
    changes: [
      "Flags. Pin and navigate to marked locations faster.",
      "Firework quick look option.",
      "View from top/bottom option.",
      "Daily rewards increased to 200g powder.",
      "Fireworks cost was reduced.",
      "Sky comet effect.",
      "Brighter fireworks colors.",
      "Other enhancements on smoke effects, scenes, and bug fixes.",
    ],
  },
  {
    version: "Version 1.1.0",
    changes: [
      "Option to show a None/Forest/City scene.",
      "Minor enhancement in tap mode.",
      "The moon object in the sky.",
      "Option to set up a starry night.",
      "Bug fixes: ad-free in the Pro Pack.",
    ],
  },
  {
    version: "Version 1.0.1",
    changes: [
      "Minor changes on UI.",
      "More options to get black powder.",
      "Option to hide the menu icon in tap mode.",
      "Bug fixes.",
    ],
  },
  {
    version: "Version 1.0.0",
    changes: [
      "Encrypt save game files.",
      "Recheck Est duration firework blueprint.",
      "Show daily reward icon if available in start screen.",
      "Tips.",
      "Can nhac play tap mode 5 phut thi show qc neu chua co show lan nao.",
      "Bug: Do not recenter cam show tap mode.",
      "Random time better.",
      "Review firework store thumb and powder amount.",
      "Bug: Undo add full set which causes an increase in the amount of powder.",
      "Review sound effects.",
      "Better icon of trail size.",
      "Message of Unity ad wifi.",
      "Bug: Not flash the ground on tap mode.",
      "Option to exclude fireworks in tap mode.",
      "Remote config.",
      "Update all projects to Unity Ads SDK 3.7.1.",
    ],
  },
];
